import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ForbiddenPage = () => (
  <Layout>
    <Seo title="403: Forbidden" isIndex={false}/>
    <section className="container top-container">
      <h1 className="top-h">403 Forbidden...</h1>
      <p className="text-center">
        アクセスしようとしたページは表示できませんでした。
      </p>
    </section>
  </Layout>
)

export default ForbiddenPage
